import * as React from "react";
import styled from "@emotion/styled";
import config from "../config";

const Wrapper = styled.div`
  background-color: #fff;
`

const Container = styled.div`
  margin: 0 auto;
  //padding: 28px 0 32px;
  height: 80px;
  width: ${config.styles.bodyWidth}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0,0,0,0.06);
`

const IcpPolice = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  & a {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC, sans-serif;
    font-weight: 400;
    color: rgba(0,0,0,0.4);
    text-decoration: none;
  }
`

const Police = styled.a`
  //display: flex;
  //gap: 12px;
  line-height: 24px;
  align-items: center;
  vertical-align: middle;
`

const PoliceIcon  = styled.img`
  margin: 0 6px 0 0;
  height: 24px;
  width: 24px;
  vertical-align: bottom;
`

const Links = styled.div`
  width: 720px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  line-height: 16px;
  & a {
    color: rgba(0,0,0,0.6);
    text-decoration: none;
  }
`


const links = [
  {
    name: '© GITWORK',
    href: '#',
  },
  {
    name: '帮助中心',
    href: '/manual'
  },
  {
    name: '合作伙伴',
    href: '/recommend',
  },
  {
    name: '平台协议',
    href: '/agreement',
  },
  {
    name: '关于我们',
    href: '/about',
  },
]

const Footer = () => {
  return (<>
    <Wrapper>
      <Container>
        <IcpPolice>
          <Police href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33019202000541" target="_blank">
            <PoliceIcon src="/images/icons/police.png"/>
            浙公网安备33019202000541
          </Police>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备18054845号-2</a>
        </IcpPolice>
        <Links>
          {links.map((link, index) => (
            <a href={link.href ?? "#"} key={index} target='_blank'>{link.name}</a>
          ))}
        </Links>
      </Container>
    </Wrapper>
  </>)
}

export default Footer;
