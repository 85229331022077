import * as React from "react";
import {Card} from "antd";
import styled from "@emotion/styled";
import config from "../../../config";
import {ReactElement, ReactNode} from "react";

const coverHeight = 218;

const Container = styled.a`
  //width: calc(33% - 33px);
  display: block;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    box-shadow: ${config.styles.hoverShadow};
  }
`

const Cover = styled.img`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${coverHeight}px;
  object-fit: cover;
`

const Name = styled.div`
  padding: 0 16px;
  line-height: 54px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
`

export type TeamCaseProps = {
  cover: string | ReactElement;
  name?: string;
  href?: string;
  width?: number | string;
}

const DefaultCover = styled.div`
  width: 100%;
  height: ${coverHeight}px;
  background-color: rgba(0, 0, 0, 0.04);
  background-image: url("/images/pages/case/cover@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 96px 113px;
`

const TeamCase = (props : TeamCaseProps) => {
  const {
    cover = <DefaultCover/>,
    name = '', href = '#!', width = 290,
  } = props
  return (<>
    <Container
      href={href}
      style={{ width }}
      target='_blank'
    >
      {typeof(cover) === 'string' && (
        <Cover src={cover} />
      ) || cover}
      {/*<Card*/}
      {/*  hoverable*/}
      {/*  cover={<Cover alt="example" src={cover} width='100%' height="218" />}*/}
      {/*  bodyStyle={{padding: 16}}*/}
      {/*>*/}
      {/*  <Name>{name}</Name>*/}
      {/*</Card>*/}
      <Name>{name}</Name>
    </Container>
  </>)
}

export default TeamCase
