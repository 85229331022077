import * as React from "react";
import Header, {HeaderNav} from "../../components/header";
import Seo from "../../components/seo"
import styled from "@emotion/styled";
import {useEffect, useState} from "react";
import jsonRequest from "../../utils/request/jsonRequest";
import * as queryString from 'query-string';
import InfiniteScroll from "react-infinite-scroll-component";
import {useSelector} from "react-redux";
import {Link} from "gatsby";
import Footer from "../../components/footer";
import TeamCase from "../../components/pages/team/TeamCase";

const bodyWidth = 938;

const OrganizePageBody = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
`

const Container = styled.div`
  padding-top: 40px;
  margin: 0 auto;
  width: ${bodyWidth}px;
`

const List = styled.div`
  padding-top: 16px;
  width: ${bodyWidth}px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const AdvLink = styled.a`
  
`
const Adv = styled.img`
    margin: 0;
    width: ${bodyWidth/2-8}px;
    //width: 356px;
    height: ${(bodyWidth/2-8)/356*154}px;
    border-radius: 8px;
    object-fit: cover;
`

const LoginLink = styled.div`
  padding: 30px 0 30px;
  text-align: center;
  a {
    color: #1890ff;
  }
`

let page = 1
const pageSize = 27

const OrganizePage = () => {
    const {account} = useSelector((selector: any) => selector)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [showLoginLink, setShowLoginLink] = useState(false)

    const load = (page = 1, params = {}) => {
        if (loading) {
            return Promise.resolve();
        }
        if (!account && page > 2) {
            setShowLoginLink(true)
            return Promise.resolve();
        }
        params['current'] = page;
        params['pageSize'] = pageSize
        setLoading(true)
        const query = {}
        Object.keys(params).map(key => {
            if (params[key] !== null && params[key] !== undefined) {
                query[key] = params[key]
            }
        })

        return jsonRequest(`/userCase?${queryString.stringify(query)}`).then(rsp => {
            setData([...(page <= 1 ? [] : data), ...(rsp.data.data ?? [])])
        }).catch(console.error).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        load()
    }, [])
    return (
        <OrganizePageBody>
            <Header selected={HeaderNav.Case} authorized/>
            <Container>
                <InfiniteScroll
                    dataLength={data.length}
                    next={() => {
                        // run(++page)
                        load(++page)
                    }}
                    hasMore={true}
                    loader={
                        // data.length > 0 && <div style={{padding: 40, textAlign: "center"}}><Spin/></div>
                        data.length > 0 && <div></div> // todo has more
                    }
                    // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List>
                        {data?.map((item) => (
                            <TeamCase
                                key={item.id}
                                width="calc(33.3% - 22px)"
                                {...{...item, cover: item.coverUrl}}
                                href={`/case/detail?id=${item.id}`}
                            />
                        ))}
                    </List>
                </InfiniteScroll>

                {/*{*/}
                {/*  loading && (*/}
                {/*    <div style={{padding: 40, textAlign: "center"}}><Spin/></div>*/}
                {/*  )*/}
                {/*}*/}
            </Container>
            {showLoginLink && (
                <>
                    <LoginLink><Link to={`/sign/in?redirect=/organize/`}>登陆</Link>后查看更多</LoginLink>
                    <Footer/>
                </>
            )}
        </OrganizePageBody>
    )
}

export default OrganizePage

// @ts-ignore
export const Head = () => <Seo title='GitWork-1小时组建IT团队' keywords={["web3","web3.0","web3人才招聘","web3人才猎头","web3远程办公","web3工作","劳务派遣","人力外包","人才猎头公司","招聘求职"]} />
